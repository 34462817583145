@font-face {
  font-family: "Styrene A Web";
  src: url("../../fonts/StyreneAWeb-Bold.woff2") format("woff2"),
  url("../../fonts/StyreneAWeb-Bold.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("../../fonts/StyreneAWeb-Medium.woff2") format("woff2"),
  url("../../fonts/StyreneAWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Styrene A Web";
  src: url("../../fonts/StyreneAWeb-Regular.woff2") format("woff2"),
  url("../../fonts/StyreneAWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
