@import "../../static/styles/inc/variables";

header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #fff;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  height: 70px;

  .logo {
    width: 250px;
    background: $color-dark-blue;
    padding: 0 20px;

    svg {
      width: 180px;
      height: 100%;
    }
  }

  .right-side {
    padding-right: 15px;
  }

  .header-dropdown-wrap {
    position: relative;

    .dropdown-btn {
      height: 100%;
      position: relative;
      padding-right: 30px;

      .chevron {
        right: 10px;
        margin-left: 15px;
        transition: all 0.2s;
      }

      &:hover {
        background: $color-bg;
      }
    }

    .dropdown-wrap {
      position: absolute;
      top: calc(100% + 20px);
      right: 0;
      width: 160px;
      border-radius: 5px;
      background: #fff;
      transition: all 0.3s;
      opacity: 0;
      visibility: hidden;
      padding: 5px 0;
      transition: all 0.3s;

      .devider-line {
        height: 1px;
        margin: 6px 0;
        background: #eff2f7;
      }
    }

    &.dropdown-visible {
      .dropdown-btn {
        background: $color-bg;

        .chevron {
          transform: translate(0, -50%) rotate(-180deg);
        }
      }

      .dropdown-wrap {
        top: calc(100% + 10px);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  #header-profile-btn {
    padding: 0 30px 0 56px;

    .avatar {
      left: 10px;
      height: 34px;
      width: 34px;
      border-radius: 100%;
    }

    span {
      width: 70px;
      @include text-overflow(1, 18px);
    }
  }

  #header-profile-dropdown {
    .dropdown-link {
      display: block;
      font-size: 13px;
      cursor: pointer;
      position: relative;
      padding: 5px 10px 6px 40px;

      svg {
        left: 15px;

        path {
          fill: $color-dark-blue;
        }
      }

      &:hover {
        background: $color-bg;
      }

      &._logout {
        opacity: 0.7;
      }
    }
  }
}
