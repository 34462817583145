@import "./variables";

a {
  outline: 0 !important;
}

/* Common */
html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  font: 400 14px/1.5 $font;
  background: $color-bg;
  color: $color-text;
  font-smoothing: antialiased;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100%;
}

/* Position */
[class*="pos-abs"] {
  position: absolute;
}

.pos {
  position: relative;

  &-abs {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-abs-y {
    top: 50%;
    transform: translate(0, -50%);
  }

  &-abs-x {
    left: 50%;
    transform: translate(-50%, 0);
  }
}

/* Image */
[class*="image-"] {
  width: 100%;
  height: 100%;
}

.image {
  &-cover {
    object-fit: cover;
  }

  &-contain {
    object-fit: contain;
  }
}

/* Scroll */
.scroll {
  &-y {
    overflow: hidden auto;
  }

  &-x {
    overflow: auto hidden;
  }
}

/* Cursor */
.cursor {
  &-pointer {
    cursor: pointer !important;
  }

  &-default {
    cursor: default !important;
  }
}
