@import "../../../../../static/styles/inc/variables";

.page-homework-view {
  .doc-item {
    .icon-wrap {
      background-color: $color-bg;

      &._remove {
        background: $color-acent;
      }
    }
  }
}
