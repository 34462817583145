.page-faq-section-list {
  table {
    .icon-render {
      width: 50px;
      height: 50px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
