@import "../variables";

.form {
  .field {
    margin-bottom: 30px;
  }

  .label {
    display: block;
    font-size: 14px;

    & > span {
      display: block;
      cursor: pointer;
      margin-bottom: 8px;
    }
  }

  .input-wrap {
    .save-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 40px;
      border-radius: 0 5px 5px 0;
      background: $color-blue;
    }

    &._btn {
      .input {
        padding-right: 50px;
      }
    }
  }

  .input {
    background: #fff;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 15px 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    @include placeholder-style(400 14px $font, rgba(0, 0, 0, 0.4));

    &._select {
      cursor: pointer;
      padding-right: 34px;
      background-image: url("./imgs/select-arrow-down.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 14px) 50%;
      background-size: 12px auto;
    }

    &._error {
      border-color: #f46a6a;
    }

    &-sm {
      padding: 5px 10px 7px;

      &._select {
        background-position: calc(100% - 8px) 50%;
        padding-right: 30px;
      }
    }
  }

  .input-radio {
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid $color-blue;
    border-radius: 100%;
    cursor: pointer;

    &:checked {
      background: $color-blue;
      box-shadow: inset 0 0 0 4px #fff;
    }
  }

  .input-checkbox {
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid $color-blue;
    border-radius: 5px;
    cursor: pointer;

    &:checked {
      background: $color-blue url("../../../imgs/icons/checkbox.svg") 50% no-repeat;
      background-size: 12px;
    }
  }

  .error {
    margin-top: 5px;
    font-size: 12px;
    color: #f46a6a;
  }

  .dropzone-wrap {
    .dropzone {
      text-align: center;
      padding: 30px;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      height: 230px;
      border: 2px dashed #ced4da;
      background: #fff;

      .accepted-files {
        margin-top: 10px;
        font-size: 13px;
        opacity: 0.6;
        font-weight: 400;
      }
    }

    .file {
      margin-top: 10px;
      border-radius: 4px;
      padding: 8px 60px 8px 8px;
      font-size: 14px;
      border: 1px solid #eff2f7;

      &-img {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;

        .doc-icon {
          width: 30px;
          height: 30px;
        }
      }

      &-size {
        font-size: 12px;
        opacity: 0.4;
      }

      &-remove {
        width: 30px;
        height: 30px;
        right: 15px;
      }
    }
  }
}
