@import "../../../../../static/styles/inc/variables";

.page-questions-answers {
  .avatar-img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
  }

  .user-info {
    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .answer-list {
    .answer {
      margin-bottom: 15px;
      background: $color-bg;
      border-radius: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .answer-wrap {
      border-left: 2px solid $color-blue;
    }
  }
}
