@import "../../../static/styles/inc/variables";

.page-chat {
  .chat-list {
    .chat-item {
      transition: all 0.4s;
      padding: 14px 16px;
      border-radius: 4px;
      border-top: 1px solid #eff2f7;
      cursor: pointer;

      .item-body {
        .thumb {
          color: #74788d;
          font-size: 13px;
        }
      }

      &:hover {
        background: #fff;
        border-color: #fff;
        box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
      }

      &._unread {
        background: #dde1e8;
      }

      &._active {
        color: #fff;
        background: $color-dark-blue;
        border-color: $color-dark-blue;
      }
    }
  }

  .chat-wrap {
    padding: 20px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: fade-out($color-blue, 0.8);

    .msg-list {
      height: 600px;

      .msg-wrap {
        .text-wrap {
          color: #302549;
        }
      }
    }

    .msg-form {
      margin-top: 20px;

      .attach-btn {
        cursor: pointer;
        left: 20px;
        width: 20px;
        height: 20px;
      }

      input,
      .textareaChat {
        width: 100%;
        resize: none;
        padding: 15px 60px 15px;
        border-radius: 5px;
        border-width: 2px;
        border-style: solid;
        border-color: #efeeee;
      }

      .send-btn {
        right: 20px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .attach-files {
    .file {
      height: 100px;
      z-index: 1;
      background: #fff;
      border-radius: 5px;

      .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 26px;
        height: 26px;
        border-radius: 3px;
        background: #cd0000;

        path {
          fill: #fff;
        }
      }

      .spinner {
        z-index: -1;
      }

      .img {
        border-radius: 6px;
      }
    }
  }
}
