@import "../../../../static/styles/inc/variables";

.page-user-view {
  .progress-bar {
    height: 14px;
    border-radius: 4px;
    background: $color-bg;
    overflow: hidden;

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: $color-blue;
    }
  }
}
