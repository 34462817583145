@import "../../static/styles/inc/variables";

#left-menu {
  width: 250px;
  z-index: 1002;
  bottom: 0;
  position: fixed;
  top: 70px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  background: $color-dark-blue;
  padding-bottom: 30px;

  ul {
    li {
      a {
        display: block;
        position: relative;
        padding: 12px 20px 12px 55px;
        font-size: 13px;
        color: #a6b0cf;
        transition: all 0.3s;

        svg {
          left: 26px;
        }

        path,
        circle {
          fill: #6a7187;
          transition: all 0.3s;
        }

        .badge {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          color: #fff;
          line-height: 24px;
          text-align: center;
          background: $color-acent;
        }

        &:hover,
        &._active {
          color: #fff;

          path,
          circle {
            fill: #fff;
          }
        }
      }

      &.menu-block-title {
        margin-top: 20px;
        text-transform: uppercase;
        color: #6a7187;
        font-weight: 500;
        font-size: 11px;
        padding: 12px 20px;
        letter-spacing: 0.4px;
      }
    }
  }
}
