.avatar {
  &-xs {
    height: 32px;
    width: 32px;
  }

  &-sm {
    height: 48px;
    width: 48px;
  }

  &-md {
    height: 72px;
    width: 72px;
  }

  &-lg {
    height: 96px;
    width: 96px;
  }

  &-xl {
    height: 120px;
    width: 120px;
  }

  &-rounded {
    border-radius: 4px;
  }

  &-circle {
    border-radius: 100%;
  }
}
