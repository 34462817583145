@import "../../static/styles/inc/variables";

#main-content {
  margin-left: 250px;
  padding: 30px 25px 50px;
  min-height: 100%;

  .page-header {
    margin-bottom: 30px;

    .title {
      color: #495057;
      text-transform: uppercase;
      letter-spacing: 0.4px;
    }
  }

  @media #{$mediaXl} {
    margin-left: 0;
  }
}
