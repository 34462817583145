@import "../variables";

table {
  width: 100%;
  font-size: 14px;
  border: 1px solid #e8e8e8;
  min-width: 1000px;
  overflow-y: scroll;

  .btn {
    padding: 5px 9px 7px;
  }

  th {
    white-space: nowrap;
    vertical-align: bottom;
    text-align: left;
    font-weight: 500;
    padding: 10px;
    position: relative;
    background: $color-bg;
    border-right: 1px solid #e8e8e8;

    &:last-child {
      border-right: none;
    }

    &.actions {
      width: 150px;
    }

    &.sortable {
      padding-right: 24px;
      cursor: pointer;

      &:before {
        display: none;
        right: 10px;
        content: "\2191";
        font-size: 13px;
        font-weight: 700;
        position: absolute;
        @extend .pos-abs-y;
        top: 46%;
      }

      &._asc,
      &._desc {
        &:before {
          display: block;
        }
      }

      &._asc {
        &:before {
          transform: translate(0, -24%) rotate(180deg);
        }
      }
    }
  }

  td {
    vertical-align: middle !important;
    border-right: 1px solid #e8e8e8;
    border-top: 1px solid #e8e8e8;
    vertical-align: top;
    padding: 10px;

    .short-text {
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 1.4;
      @include text-overflow(3, 14px * 1.4);
    }

    &.actions {
      width: 150px;
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  @media #{$mediaMd} {
    td {
      padding: 5px;
    }
  }
}
