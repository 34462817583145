@import "../../static/styles/inc/variables";

.auth-wrap {
  overflow: hidden;
  margin: 50px 0;

  .wrap-header {
    background-color: lighten($color-blue, 55%);
    color: $color-blue;
    font-size: 18px;

    .text {
      padding: 25px;

      h4 {
        margin-bottom: 10px;
      }
    }

    img {
      max-width: 100%;
    }
  }

  .wrap-body {
    padding: 25px;

    .thumb-btns {
      margin-top: 25px;

      a {
        font-weight: 400;
        color: $color-blue;
      }
    }
  }
}
