@import "src/static/styles/inc/variables";

.msg-wrap {
  margin-bottom: 20px;
  max-width: 70%;
  align-self: flex-start;

  .text-wrap {
    border-radius: 12px;
    padding: 8px 15px 10px;
    font-size: 15px;
    line-height: 1.5;
    background: #f5f5f5;
    color: #302549;
    white-space: pre-wrap;

    path {
      fill: #302549;
    }
  }

  .doc-img {
    max-width: 320px;
    max-height: 240px;
    border-radius: 5px;
  }

  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    left: 12px;
  }

  .time {
    margin: 5px 0 0;
    font-size: 11px;
    color: #9ea4a9;
  }

  &._me {
    align-self: flex-end;
    text-align: right;

    .text-wrap {
      background: $color-blue !important;
      color: #fff !important;

      path {
        fill: #fff;
      }
    }

    .avatar {
      right: 12px;
      left: auto !important;
    }
  }

  &._homework {
    width: 100%;
    max-width: 100%;
    text-align: center;

    .text-wrap {
      background: $color-blue;
      color: #fff !important;
    }

    &._reject {
      .text-wrap {
        background: $color-acent;
      }
    }

    &._success {
      .text-wrap {
        background: $color-success;
      }
    }
  }

  @media #{$mediaXxl} {
    .text-wrap {
      padding: 12px 15px;
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 5px;
    }
  }

  @media #{$mediaLg} {
    .text-wrap {
      border-radius: 8px;
    }
  }

  @media #{$mediaMd} {
    margin-bottom: 15px;
    max-width: 90%;
  }

  @media #{$media475} {
    .text-wrap {
      font-size: 13px;
      padding: 6px 12px;
    }

    .doc-img {
      max-width: 200px;
      max-height: 150px;
    }
  }

  @media #{$media375} {
    max-width: 100%;
  }
}
