/* Font */
$font: "Styrene A Web", Arial, sans-serif;

/* Colors */
$color-bg: #f8f8fb;
$color-acent: #ef4a37;
$color-success: #034e03;
$color-text: #495057;
$color-blue: #1952a5;
$color-dark-blue: #2a3042;

/* Media queries */
$mediaXxl: "(max-width: 1559.98px)";
$mediaXl: "(max-width: 1199.98px)";
$mediaLg: "(max-width: 991.98px)";
$mediaMd: "(max-width: 767.98px)";
$mediaSm: "(max-width: 575.98px)";
$media475: "(max-width: 475.98px)";
$media375: "(max-width: 375.98px)";
$media320: "(max-width: 320.98px)";

/* Functions */
@mixin background($path, $position: center center, $repeat: no-repeat) {
  background: url($path) $position $repeat;
  background-size: cover;
}

@mixin text-overflow($lineClamp, $lineHeight) {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineClamp;
  overflow: hidden;
  max-height: $lineClamp * $lineHeight;
}

@mixin placeholder-style($placeholder-font, $placeholder-color) {
  &::-webkit-input-placeholder {
    font: $placeholder-font;
    color: $placeholder-color;
  }

  &::-moz-placeholder {
    font: $placeholder-font;
    color: $placeholder-color;
  }

  &:-moz-placeholder {
    font: $placeholder-font;
    color: $placeholder-color;
  }

  &:-ms-input-placeholder {
    font: $placeholder-font;
    color: $placeholder-color;
  }
}

@mixin flex-width($width) {
  width: 100%;
  max-width: $width;
}
