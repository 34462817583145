@import "../variables";

.h1,
h1 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 500;
}

.h2,
h2 {
  font-size: 32px;
  line-height: 1.3;
  font-weight: 500;
}

.h3,
h3 {
  font-size: 28px;
  line-height: 1.3;
  font-weight: 500;
}

.h4,
h4 {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 500;
}

.h5,
h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
}

.h6,
h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

p {
  font-size: 14px;
  line-height: 1.4;
}

.h7 {
  font-size: 12px;
  line-height: 1.4;
}

/* Align */
.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

/* Font weight */
.font-700 {
  font-weight: 700;
}

.font-500 {
  font-weight: 500;
}

.font-400 {
  font-weight: 400;
}
