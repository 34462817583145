@import "../inc/variables";

.card {
  margin-bottom: 30px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  overflow: hidden;

  &-img {
    height: 250px;
    border-radius: 5px;

    &._block {
      height: auto;
      width: 100%;
    }
  }

  &-body {
    padding: 15px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  &-price {
    font-size: 14px;
    margin-bottom: 3px;

    u {
      margin-right: 5px;
      color: $color-acent;
      text-decoration: line-through;
    }
  }

  &-title {
    margin-bottom: 10px;
    @include text-overflow(2, 20px * 1.4);
  }

  &-text {
    margin-bottom: 10px;
    @include text-overflow(3, 14px * 1.4);
  }

  &-btns {
    display: flex;
    flex-wrap: wrap;

    .btn {
      margin: 10px 10px 0 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
