@import "../variables";

.btn {
  line-height: 1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.15s;
  padding: 10.5px 13px 13.5px;

  &-primary {
    color: #fff;
    background: $color-blue;

    &:hover {
      background: darken($color-blue, 5%);
    }
  }

  &-info {
    color: #fff;
    background: #2d93ee;

    &:hover {
      background: darken(#2d93ee, 5%);
    }
  }

  &-success {
    color: #fff;
    background: #2ca579;

    &:hover {
      background: darken(#2ca579, 5%);
    }
  }

  &-warning {
    color: #fff;
    background: #eea529;

    &:hover {
      background: darken(#eea529, 5%);
    }
  }

  &-danger {
    color: #fff;
    background: #f14646;

    &:hover {
      background: darken(#f14646, 5%);
    }
  }

  &-bg {
    color: $color-text;
    background: $color-bg;

    &:hover {
      background: darken($color-bg, 5%);
    }
  }

  &-lg {
    font-size: 15px;
    padding: 14px 20px 15px;
    border-radius: 6px;
  }

  &-sm {
    font-size: 13px;
    padding: 7px 12px 9px;
  }

  &-xs {
    font-size: 12px !important;
    padding: 4px 10px 5px;
  }

  &-block {
    width: 100%;
  }
}
