@import "../../static/styles/inc/variables";

.modal {
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  padding: 60px 0 30px;

  .wrapper {
    width: 94%;
    max-width: 500px;
    background: #fff;
    padding: 25px;
    position: relative;
    z-index: 101;
    border: 10px solid #f5f5f5;
    box-shadow: 0 20px 57px 0 rgba(44, 44, 43, 0.32);

    img {
      max-width: 100%;
      margin: 0 auto;
      display: flex;
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .js-close-modal-icon {
    cursor: pointer;
    position: absolute;
    right: -15px;
    top: -58px;
    width: 24px;
    height: 24px;
  }

  .modal__title {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: 600;
    line-height: 1.3;
  }

  p {
    color: $color-text;
  }

  .btn {
    margin-top: 20px;
  }

  .forgot-pass-wrapper {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #9b9b9b;
    font-size: 12px;
    margin-top: 25px;

    a {
      margin-top: 10px;
    }
  }

  .politic-agree {
    margin-top: 30px;
    font-size: 13px;

    a {
      text-decoration: underline;
    }
  }

  .video-wrapper {
    height: 540px;
  }

  &.is-visible {
    display: flex !important;
  }
}

.modal--sm-wide {
  .wrapper {
    max-width: 670px;
  }
}

.modal--sm-padding {
  .wrapper {
    border-width: 10px;
    padding: 10px;
  }
}

.modal--wide {
  .wrapper {
    max-width: 1000px;
  }
}

.modal--no-border {
  .wrapper {
    border: none;
  }
}

#modal-choose-city {
  .js-close-modal-icon {
    right: 0;
    top: -40px;
  }
}

.modal--notify {
  .notify-type {
    margin: -41px -36px 70px -36px;
    height: 60px;
    background: #f5f5f5;

    .ifont {
      background: #fff;
      border-radius: 100%;
      padding: 10px;
      font-size: 80px;
      height: 100px;
      transform: translate(0, 10px);
    }
  }

  .is-error {
    color: #f72e2e;
  }

  .is-success {
    color: $color-blue;
  }

  p {
    color: #3f4142;

    a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .btns {
    margin: 30px -8px 0;

    .btn {
      margin: 0 4px;
      flex: 1 1 auto;
    }
  }
}
