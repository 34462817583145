.pagination {
  border: 1px solid #878d9c;
  background: #f5f5f5;
  border-radius: 0.25em;
  overflow: hidden;

  .link {
    cursor: pointer;
    display: inline-block;
    color: #878d9c;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;

    &:not(:last-child) {
      border-right: 1px solid #878d9c;
    }

    &:hover {
      color: #616264;
    }

    &._active {
      color: #fff;
      background: #2a3042;
      border-color: #646464;
    }
  }
}
